import { isBefore, parse, startOfDay, subDays } from "date-fns";
import moment from "moment-timezone";

export const getFilterDays = (months, isSocialMedia = false) => {
  const endDate = moment();
  const startDate = moment(endDate).subtract(months, "months"); // Subtract 'months' from end date
  return endDate.diff(startDate, "days"); // Difference in days
};

export const getStartDate = filterText => {
  const endDate = moment().toDate();
  const startDate =
    filterText === "Last 3 months"
      ? startOfDay(subDays(endDate, 92))
      : filterText === "Last month"
      ? startOfDay(subDays(endDate, 30))
      : filterText === "Last 6 months"
      ? startOfDay(subDays(endDate, 182))
      : filterText === "Last 12 months"
      ? startOfDay(subDays(endDate, 363))
      : startOfDay(subDays(endDate, 7));

  return moment(startDate).toLocaleString();
};

export function convertDateToFilterFormat(start, end) {
  console.log("Before Conversion", { start, end });
  console.log("After Conversion", {
    start: getLocalToUtcConversion(start),
    end: getLocalToUtcConversion(end)
  });

  return {
    start: getLocalToUtcConversion(start),
    end: getLocalToUtcConversion(end)
  };
}

export function getTimeDifference(createdAt) {
  // Parse the creation time to a moment object
  const createdDate = moment(createdAt);
  const now = moment();
  const daysDifference = now.diff(createdDate, "days");
  let timeDifference;

  if (daysDifference === 0) {
    const hoursDifference = now.diff(createdDate, "hours");
    const minutesDifference = now.diff(createdDate, "minutes");

    if (minutesDifference < 1) {
      timeDifference = "Just now";
    } else if (minutesDifference < 60) {
      timeDifference = `${minutesDifference} minutes ago`;
    } else if (hoursDifference < 24) {
      timeDifference = `${hoursDifference} hours ago`;
    } else {
      timeDifference = "Today";
    }
  } else if (daysDifference === 1) {
    timeDifference = "Yesterday";
  } else {
    timeDifference = createdDate.format("MMMM D, YYYY");
  }

  return timeDifference;
}

export function convertToAbbreviatedMonth(dateString) {
  try {
    // Check if the dateString includes a day (e.g., '2023-12-05') or just the month (e.g., '2023-12')
    const hasDay = dateString.split("-").length === 3;

    // If it includes a day, use the dateString as is; otherwise, append '-01' for the 1st day
    const formattedDateString = hasDay ? dateString : `${dateString}-01`;

    // Convert to Date object
    const dateObject = new Date(formattedDateString);

    // Get abbreviated month name
    return dateObject.toLocaleString("default", { month: "short" });
  } catch (error) {
    // Handle invalid date strings
    console.error("Invalid date string:", dateString);
    return null;
  }
}

export function convertToAbbreviatedMonthAndYear(dateString) {
  try {
    // Convert to Date object
    const dateObject = new Date(dateString + "-01"); // Assuming day is the 1st of the month

    // Get abbreviated month name and year
    const formattedMonth = dateObject.toLocaleString("default", { month: "short" });
    const year = dateObject.getFullYear();

    return `${formattedMonth} ${year}`;
  } catch (error) {
    // Handle invalid date strings
    console.error("Invalid date string:", dateString);
    return null;
  }
}

function formatDaysAgo(differenceInDays) {
  const absoluteDifference = Math.abs(differenceInDays);

  switch (true) {
    case absoluteDifference < 1:
      return "today";
    case absoluteDifference === 1:
      return "1 day ago";
    case absoluteDifference < 7:
      return `${Math.floor(absoluteDifference)} days ago`;
    case absoluteDifference < 14:
      return "1 week ago";
    case absoluteDifference < 28:
      return `${Math.floor(absoluteDifference / 7)} weeks ago`;
    case absoluteDifference < 60:
      return "1 month ago";
    case absoluteDifference < 365:
      return `${Math.floor(absoluteDifference / 30)} months ago`;
    case absoluteDifference < 730:
      return "1 year ago";
    default:
      return `${Math.floor(absoluteDifference / 365)} years ago`;
  }
}

export function getDaysDifference(value) {
  try {
    // Parse the input date strings
    const dateObject = new Date(value);
    const referenceDateObject = new Date();

    // Calculate the difference in days
    const timeDifference = dateObject.getTime() - referenceDateObject.getTime();
    const daysDifference = timeDifference / (24 * 60 * 60 * 1000);
    const absoluteDifference = Math.abs(daysDifference);

    return formatDaysAgo(Math.floor(absoluteDifference));
  } catch (error) {
    // Handle invalid date strings
    console.error("Invalid date string:", value);
    return null;
  }
}

const getTime = () => new Date().toISOString().split("T")[1].split(".")[0];

export const getIsBefore2AM = () => {
  const currentTime = parse(getTime(), "HH:mm:ss", new Date());
  const referenceTime = parse("02:00:00", "HH:mm:ss", new Date());

  return isBefore(currentTime, referenceTime);
};

export const convertIsoDate = inputDate => {
  const parsedDate = new Date(inputDate);
  return parsedDate.toISOString().split("T")[0];
};

export const convertDate = value => {
  const year = parseInt(value.substr(0, 4), 10);
  const month = parseInt(value.substr(5, 2), 10);
  const day = parseInt(value.substr(8, 2), 10);

  // Create a Date object
  const date = new Date(year, month - 1, day);

  // Format the date as "MM/DD" (e.g., "11/09")
  const formattedMonth = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
  const formattedDay = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();

  return `${formattedMonth}/${formattedDay}`;
};

export function convertDateFormat(inputDate) {
  const [year, month, day] = inputDate.split("-");
  const shortYear = year.slice(-2);
  return `${month}/${day}/${shortYear}`;
}

export function convertToMonthYear(dateString) {
  const date = new Date(dateString);
  const options = { year: "numeric", month: "long" };
  return date.toLocaleDateString("en-US", options); // Output: April 2023;
}

export function convertToDayMonthYear(dateString) {
  const date = new Date(dateString);
  const day = date.getDate() + 1; // Get the day of the month (1-31)
  const month = date.toLocaleString("en-US", { month: "short" }); // Get the month abbreviation (Jan, Feb, ...)
  const year = date.getFullYear(); // Get the full year (YYYY)

  // Format the date as "DD Mon YYYY"
  return `${day} ${month}, ${year}`;
}

export function getDateMonthYearObj(dateString) {
  const date = new Date(dateString);
  const day = date.getDate() + 1; // Get the day of the month (1-31)
  const month = date.toLocaleString("en-US", { month: "short" }); // Get the month abbreviation (Jan, Feb, ...)
  const year = date.getFullYear(); // Get the full year (YYYY)

  // Return the date components separately
  return { day, month, year };
}

export const formatToDateAndTime = inputDate => {
  const date = moment(inputDate);

  const formattedDate = date.local().format("D, MMMM YYYY HH:mm");

  return formattedDate;
};

export const formatCombinedDateAndTime = timestamp => {
  const date = new Date(timestamp);
  const options = {
    weekday: "short",
    year: "2-digit",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true
  };

  const dateString = date.toLocaleDateString("en-US", options);
  const timeString = date.toLocaleTimeString("en-US", options);

  return `${dateString}`;
};

export function getDateInfo(dateString) {
  const date = new Date(dateString);
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Adding 1 because getMonth() returns zero-based month
  const year = date.getFullYear().toString();
  const day = String(date.getDate()).padStart(2, "0");

  // Calculate quarter
  const quarter = Math.floor((month - 1) / 3) + 1;

  const formattedDate = `${year}-${month}-${day}`;

  return { date: formattedDate, month, quarter, year };
}

export function calculateMonthDifference({ start, end }) {
  // Parse the start and end dates using Moment.js
  const startDate = moment(start);
  const endDate = moment(end);

  // Calculate the difference in months, including the fractional month part
  const monthsDifference = endDate.diff(startDate, "months", true);

  return monthsDifference;
}

function getLocalToUtcConversion(date) {
  const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const localDateTime = moment(date).tz(localTimeZone);

  // Convert to UTC
  return localDateTime.utc().format("YYYY-MM-DD");
}

import { handleError } from "@helpers/store-helper";
import { createSlice } from "@reduxjs/toolkit";
import {
  fetchAdsData,
  fetchFacebookAdvertisers,
  fetchGoogleAdvertisers,
  resetAdCache,
  selectFacebookAdvertiser,
  selectGoogleAdvertiser
} from "./action";

const setAdsData = (state, action) => {
  state.isAdsDataLoading = false;
  state.adsAnalyticsData = action?.payload?.analytics;
  state.adsCampaignData = action?.payload?.campaigns;
  state.adsKeywordsData = action?.payload?.keywords;
  state.adsData = action?.payload;
};

const setError = (state, action) => {
  state.isAdsDataLoading = false;

  const message = action?.payload?.response?.data?.message;
  const statusCode = action?.payload?.response?.data?.statusCode;

  // Checking if no advertiser is selected
  const noSelectedGoogleAdvertiser = !state.googleAdvertisers?.advertisers?.some(ad => ad.selected);
  const noSelectedFacebookAdvertiser = !state.facebookAdvertisers?.advertisers?.some(ad => ad.selected);

  if (noSelectedGoogleAdvertiser && noSelectedFacebookAdvertiser) {
    return;
  }

  state.errorMessage = message;

  handleError(message, statusCode);
};

export const appAdvertismentSlice = createSlice({
  name: "appAdvertisment",
  initialState: {
    googleAdvertisers: {},
    facebookAdvertisers: {},
    adsData: {},
    adsAnalyticsData: {},
    adsCampaignData: {},
    adsKeywordsData: {},
    isAdsDataLoading: false
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchGoogleAdvertisers.fulfilled, (state, action) => {
        state.googleAdvertisers = action.payload;
      })
      .addCase(fetchGoogleAdvertisers.pending, state => {})
      .addCase(fetchGoogleAdvertisers.rejected, setError)
      .addCase(selectGoogleAdvertiser.fulfilled, (state, action) => {
        state.googleAdvertisers.advertisers = state.googleAdvertisers.advertisers.map(
          advertiser =>
            advertiser.id === action.payload.advertiserId
              ? { ...advertiser, selected: true } // Match and set selected to true
              : { ...advertiser, selected: false } // Ensure others are false
        );
      })

      .addCase(fetchFacebookAdvertisers.fulfilled, (state, action) => {
        state.facebookAdvertisers = action.payload;
      })
      .addCase(fetchFacebookAdvertisers.pending, state => {})
      .addCase(fetchFacebookAdvertisers.rejected, state => {})
      .addCase(selectFacebookAdvertiser.fulfilled, (state, action) => {
        state.facebookAdvertisers.advertisers = state.facebookAdvertisers.advertisers.map(
          advertiser =>
            advertiser.id === action.payload.advertiserId
              ? { ...advertiser, selected: true } // Match and set selected to true
              : { ...advertiser, selected: false } // Ensure others are false
        );
      })
      .addCase(fetchAdsData.fulfilled, setAdsData)
      .addCase(fetchAdsData.pending, state => {
        state.isAdsDataLoading = true;
      })
      .addCase(fetchAdsData.rejected, setError)
      .addCase(resetAdCache.fulfilled, (state, action) => {
        state.isAdsDataLoading = false;
      })
      .addCase(resetAdCache.pending, state => {
        state.isAdsDataLoading = true;
      })
      .addCase(resetAdCache.rejected, setError);
    // .addCase(fetchKeywords.pending, state => {})
    // .addCase(fetchKeywords.rejected, state => {})
    // .addCase(fetchKeywords.fulfilled, (state, action) => {
    //   state.adsKeywordsData = action.payload;
    // });
  }
});

export default appAdvertismentSlice.reducer;

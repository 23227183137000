// src/redux/slices/rolesSlice.ts
import { createSlice } from "@reduxjs/toolkit";

import { PASSWORD_NOT_SELECTED } from "@constants/response-messages";
import toast from "react-hot-toast";
import {
  confirmInvite,
  createProvider,
  createRole,
  deletePersonnel,
  deleteProvider,
  deleteRoleByName,
  fetchPersonnelList,
  fetchRolesList,
  fetchRolesPermissions,
  getMyRole,
  getProviders,
  getRoleByName,
  invitePersonnel,
  updatePerson,
  updateProvider,
  updateRoleByName
} from "./action";

const setError = (state, action) => {
  state.status = false;
  state.isRoleLoading = false;
  state.createProviderSuccess = false;
  state.updateProviderSuccess = false;
  state.deleteProviderSuccess = false;
  state.isPersonnelLoading = false;
  state.invitePersonnelSuccess = false;
  state.updatePersonSuccess = false;
  state.deletePersonSuccess = false;
  state.error = action.payload?.response?.data?.message;

  if (action.payload?.response?.data?.message !== PASSWORD_NOT_SELECTED) {
    toast.error(action.payload?.response?.data?.message, {
      duration: 2000
    });
  }
};

const setPermissions = (state, action) => {
  state.permissions = action.payload.data;
  state.newRolePermissions = {
    roleName: "",
    permissions: action.payload.data.map(permissionName => ({
      permissionName,
      read: false,
      write: false
    }))
  };
};

export const appRoles = createSlice({
  name: "appRoles",
  initialState: {
    role: {},
    roles: [],
    providers: [],
    createProviderSuccess: false,
    updateProviderSuccess: false,
    deleteProviderSuccess: false,
    myRole: { role: "", permissions: [] },
    permissions: [],
    personnel: [],
    isPersonnelLoading: false,
    status: null,
    invitePersonnelSuccess: false,
    updatePersonSuccess: false,
    deletePersonSuccess: false,
    newRolePermissions: {
      roleName: "",
      permissions: [
        {
          permissionName: "",
          read: false,
          write: false
        }
      ]
    },
    isRoleLoading: false,
    error: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getRoleByName.fulfilled, (state, action) => {
        state.role = action.payload.data;
        state.isRoleLoading = false;
        state.error = false;
      })
      .addCase(getMyRole.fulfilled, (state, action) => {
        state.myRole = action.payload.data;
        state.isRoleLoading = false;
        state.error = false;
      })
      .addCase(createRole.fulfilled, (state, action) => {
        state.roles = [action.payload.data.roleName, ...state.roles];
        state.isRoleLoading = false;
        state.error = false;
      })

      .addCase(fetchRolesList.fulfilled, (state, action) => {
        state.roles = action.payload;
        state.isRoleLoading = false;
        state.error = false;
      })
      .addCase(getRoleByName.pending, (state, action) => {
        state.error = null;
        state.isRoleLoading = true;
      })
      .addCase(getMyRole.pending, (state, action) => {
        state.error = null;
        state.isRoleLoading = true;
      })
      .addCase(fetchRolesList.pending, (state, action) => {
        state.error = null;
        state.isRoleLoading = true;
      })
      .addCase(getProviders.fulfilled, (state, action) => {
        state.providers = action.payload;
        state.isProvidersLoading = false;
      })
      .addCase(getProviders.pending, (state, action) => {
        state.isProvidersLoading = true;
      })
      .addCase(getProviders.rejected, (state, action) => {
        state.isProvidersLoading = false;
      })
      .addCase(createProvider.fulfilled, (state, action) => {
        state.providers = [action.payload, ...state.providers];
        state.createProviderSuccess = true;
      })
      .addCase(createProvider.pending, (state, action) => {
        state.createProviderSuccess = false;
      })
      .addCase(createProvider.rejected, setError)
      .addCase(updateProvider.fulfilled, (state, action) => {
        state.updateProviderSuccess = true;
      })
      .addCase(updateProvider.pending, (state, action) => {
        state.updateProviderSuccess = false;
      })
      .addCase(updateProvider.rejected, setError)
      .addCase(deleteProvider.fulfilled, (state, action) => {
        state.providers = state.providers.filter(provider => provider.id !== action.payload);
        state.deleteProviderSuccess = true;
      })
      .addCase(deleteProvider.pending, (state, action) => {
        state.deleteProviderSuccess = false;
      })
      .addCase(deleteProvider.rejected, setError)
      .addCase(getRoleByName.rejected, setError)
      .addCase(fetchRolesList.rejected, setError)
      .addCase(updateRoleByName.rejected, setError)
      .addCase(getMyRole.rejected, (state, action) => {
        state.status = false;
        state.isRoleLoading = false;
        state.error = action.payload?.response?.data?.message;
      })
      .addCase(fetchRolesPermissions.fulfilled, setPermissions)
      .addCase(invitePersonnel.fulfilled, (state, action) => {
        state.invitePersonnelSuccess = true;
        state.personnel = [...state.personnel, action.payload];
      })
      .addCase(fetchPersonnelList.fulfilled, (state, action) => {
        state.error = null;
        state.isPersonnelLoading = false;
        state.personnel = action.payload;
      })
      .addCase(updatePerson.fulfilled, (state, action) => {
        state.error = null;
        // Update the matching personnel object
        state.personnel = state.personnel.map(person =>
          person.id === action.payload.userId ? { ...person, ...action.payload } : person
        );
        state.updatePersonSuccess = true;
      })
      .addCase(deletePersonnel.fulfilled, (state, action) => {
        state.error = null;
        state.personnel = state.personnel.filter(person => person.id !== action.payload);
        state.deletePersonSuccess = true;
      })
      .addCase(deleteRoleByName.fulfilled, (state, action) => {
        state.roles = state.roles.filter(role => role !== action.payload);
        state.error = null;
      })
      .addCase(deleteRoleByName.pending, (state, action) => {
        state.error = null;
      })
      .addCase(deletePersonnel.rejected, setError)
      .addCase(deleteRoleByName.rejected, setError)
      .addCase(updatePerson.rejected, setError)
      .addCase(invitePersonnel.rejected, setError)
      .addCase(fetchPersonnelList.pending, (state, action) => {
        state.error = null;
        state.isPersonnelLoading = true;
      })
      .addCase(fetchPersonnelList.rejected, (state, action) => {
        state.error = true;
        state.isPersonnelLoading = false;
      })
      .addCase(confirmInvite.rejected, setError);
  }
});

export default appRoles.reducer;
